import React from 'react';
import LayoutEN from '../components/layout.en';
import Seo from '../components/seo.en';
import SolutionsBlockEN from '../components/solutionsblock.component.en';
import Benefit from '../components/benefit.component';
import BottomCTA from '../components/bottomCTA.component.en';

import '../styles/solution.scss';

import Zoom from 'react-medium-image-zoom';
import '../styles/imagezoom.css';

export default function SolutionRPA() {
	return (
		<LayoutEN>
			<Seo title='Smart Automation' />
			<section id='introSolution'>
				<div className='solutionBg solution-rpa'>
					<div id='introSolution-text'>
						<h1>Smart Automation</h1>
						<p>Cons? It will only do what you teach it to do.</p>
						<p>Pros? It will never ask you for a raise.</p>
					</div>
				</div>
			</section>
			<section id='benefitsSolution'>
				<Benefit posImage='R' imageUrl='/images/kuflow_critical-automation.png'>
					<h2>Automate all your critical processes</h2>
					<p>KuFlow is going to be your best player:</p>
					<ol>
						<li>
							<strong>Quickly learns </strong>any defined task.
						</li>
						<li>
							<strong>Executes them even faster</strong>.
						</li>
						<li>
							<strong>Works 24/7</strong>.
						</li>
						<li>
							<strong>You can see all running bots</strong>, their status and
							statistics.
						</li>
					</ol>
					<Zoom>
						<img
							src='/images/kuflow_screenshot_processlist_01_EN.png'
							width='280'
							style={{ margin: '0 auto' }}
							alt='Process List'
						/>
					</Zoom>
				</Benefit>
				<Benefit posImage='L' imageUrl='/images/kuflow_handshake.png'>
					<h2>Gets along well with what you already have</h2>
					<p>It's about helping, not throwing a cog in the wheels</p>
					<p>
						That's why <strong>KuFlow can connect with anything</strong>.
					</p>
					<p>
						You want to connect it with an application that has an{' '}
						<strong>API</strong>? No problem, they'll understand each other.
					</p>
					<p>
						No API? No problem,{' '}
						<strong>KuFlow can also read the screen and click</strong> where you
						teach it.
					</p>
				</Benefit>
				<Benefit posImage='R' imageUrl='/images/kuflow_world-class-rpa.png'>
					<h2>Worry free RPA</h2>
					<p>
						KuFlow is designed to be <strong>resilient</strong>. This means:
					</p>
					<ol>
						<li>
							It handles <strong>retries</strong>.
						</li>
						<li>
							It handles connection <strong>timeouts</strong>.
						</li>
						<li>
							It handles <strong>asynchronous processes</strong> perfectly.
						</li>
						<li>
							<strong>It stores request states</strong> so nothing goes under
							the radar.
						</li>
					</ol>
					<Zoom>
						<img
							src='/images/kuflow_screenshot_RPAdetails_01_ES.png'
							width='280'
							style={{ margin: '0 auto' }}
							alt='RPA retrying'
						/>
					</Zoom>
				</Benefit>
				<Benefit posImage='L' imageUrl='/images/kuflow_devs.png'>
					<h2>No-code? No, thanks</h2>
					<p>We're not so fond of boxes and arrow. Just a pinch.</p>
					<p>Nor are we particularly keen on macros or low-code either.</p>
					<p>
						<strong>
							We like well defined steps, verbose variables and that if
							something fails, we have the tools to know why.
						</strong>
					</p>
					<Zoom>
						<img
							src='/images/kuflow_screenshot_code01.png'
							width='280'
							style={{ margin: '0 auto' }}
							alt='Workflow code'
						/>
					</Zoom>
				</Benefit>
				<Benefit posImage='R' imageUrl='/images/kuflow_happy-customer.png'>
					<h2>Your customers will appreciate it</h2>
					<p>
						Just <strong>by automating very few things</strong> (tickets, leads,
						reminders, payment notices, etc.) your customer's experience will
						dramatically improve.
					</p>
					<p>
						Remember, <i>under promise and over deliver</i>.
					</p>
				</Benefit>
			</section>
			<SolutionsBlockEN />
			<BottomCTA />
		</LayoutEN>
	);
}
